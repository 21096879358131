<template>
  <v-container fluid class="py-0">
    <!-- 系统bar -->
    <v-app-bar app color="indigo darken-1" dark dense>
      <!-- 返回上一页按钮 -->
      <v-btn icon @click="back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- 系统标题 -->
      <v-toolbar-title>遥感工具平台</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- 登出 -->
      <span>{{ username }}</span>
      <v-btn icon fab small @click="logout()">
        <v-icon size="18">mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer style="float:left" permanent>
      <v-list>
        <v-list-item-title class="text-center title font-weight-bold mb-2">操作</v-list-item-title>
        <v-list-item-group v-model="selectedItem" color="primary">
          <template v-for="(item, i) in items">
            <div v-if="item.hidden" :key="i">
              <v-list-group :value="true" v-if="role == 'sysAdmin' && i == 3" no-action style="width:100%">
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="(sysItem, i) in sysItems" :key="i" @click="toPage(sysItem.path)" link>
                  <v-list-item-icon>
                    <v-icon v-text="sysItem.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ sysItem.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>

              <v-list-item v-else @click="toPage(item.path)" :key="i" :class="item.class">
                <template>
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </div>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-row>
      <!-- tab 操作栏 -->

      <v-col cols="12" sm="8">
        <!-- 子组件 -->
        <router-view :role="role" :username="username" :invitationCode="invitationCode" @tips="tips(arguments)">
        </router-view>
      </v-col>

      <!-- 其他信息 -->
      <v-col cols="12" sm="4">
        <v-card color="rgb(21,101,192,0.85)" class="white--text" elevation="0">
          <v-card-title class="mx-4">
            <v-img src="../assets/scenes/passiveSystems.png" max-width="105" max-height="100" min-height="80"
              min-width="75"></v-img>
            <div class="font-weight-bold">国家高分观测系统威海研究中心</div>
          </v-card-title>
          <v-card-text class="white--text text-center px-0 pb-0">
            <v-row justify="center" no-gutters>
              <v-col cols="6" style="background:rgb(1,87,155,0.5);margin-right:1px">
                <div>{{ orders.length }}</div>
                <div>项目</div>
              </v-col>
              <v-col cols="5.9" style="background:rgb(1,87,155,0.5)">
                <div>{{ imageLength }}</div>
                <div>图像</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-4 white--text" color="grey darken-3" elevation="0">
          <v-card-subtitle class="white--text font-weight-bold">图集展示</v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="3">
                <v-img :src="results[0].src" max-height="200" min-height="150"></v-img>
              </v-col>
              <v-col cols="3">
                <v-img :src="results[1].src" max-height="200" min-height="150"></v-img>
              </v-col>
              <v-col cols="6">
                <v-img :src="results[2].src" max-height="200" height="150"></v-img>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                <v-row dense>
                  <v-col cols="12">
                    <v-img :src="results[3].src" min-height="200" height="150"></v-img>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-img :src="results[4].src" min-height="200" height="150"></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <v-img :src="results[7].src" min-height="410"></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense>
                  <v-col cols="12">
                    <v-img :src="results[6].src" height="200"></v-img>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-img :src="results[5].src" height="200"></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-snackbar v-model="snackbar" :color="color" :timeout="2000" :top="true">
        {{ text }}
      </v-snackbar>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios"; //基于Promise的HTTP客户端
import qs from "qs";
import commonCfg from "../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.104:64011/";
// let version = "";
export default {
  data () {
    return {
      // tips
      snackbar: false,
      text: "",
      color: "",
      results: [
        // 个人图集列表
        {
          src: require("../assets/scenes/0.3米卫星影像汽车.png"),
          name: "0.3米卫星影像汽车"
        },
        {
          src: require("../assets/scenes/0.5米卫星影像农田.png"),
          name: "0.5米卫星影像农田"
        },
        {
          src: require("../assets/scenes/0.5米卫星影像机场.png"),
          name: "0.5米卫星影像机场"
        },
        {
          src: require("../assets/scenes/数据处理.jpg"),
          name: "60年代锁眼卫星影像图"
        },
        {
          src: require("../assets/scenes/sensing.jpg"),
          name: "土地利用分类"
        },
        {
          src: require("../assets/scenes/国内卫星.jpg"),
          name: "植被提取"
        },
        {
          src: require("../assets/scenes/国际卫星.jpg"),
          name: "水域提取"
        },
        {
          src: require("../assets/scenes/starlink.jpg"),
          name: "环境监测与污染分析"
        }
      ],
      items: [],
      sysItems: [
        {
          text: "用户管理",
          icon: "mdi-account-group",
          path: "userManagement"
        },

        {
          text: "用户认证",
          icon: "mdi-card-account-details",
          path: "userAuth"
        },
        {
          text: "账号申请",
          icon: "mdi-newspaper",
          path: "accountApply"
        }
      ],
      invitationCode: "",
      internal: false,
      selectedItem: 0, //操作类型 0-项目 1-个人信息 2-系统消息
      role: "", //角色
      username: localStorage.getItem("username"),
      orders: [], //订单列表
      imageLength: 0 //图像数量
    };
  },
  async created () {
    this.role = this.$root.role;
    await this.getUserInfo();
    console.log(this.internal);
    this.items = [
      {
        text: "项目列表",
        icon: "mdi-newspaper",
        path: "/projectList",
        hidden: true
      },
      {
        text: "个人资料",
        icon: "mdi-account",
        path: "/userInfo",
        hidden: true
      },
      { text: "系统消息", icon: "mdi-bell", path: "/message", hidden: true },
      {
        text: "用户管理",
        icon: "mdi-account-cog",
        path: "/customer",
        hidden: this.role == "projectAdmin" || this.role == "sysAdmin",
        class: this.role == "sysAdmin" ? "no_padding" : null
      },
      {
        text: "项目对接",
        icon: "mdi-account-cog",
        path: "/projectManager",
        hidden: this.role == "projectAdmin" && this.internal
      },
      {
        text: "工具管理",
        icon: "mdi-toolbox",
        path: "/tools",
        hidden: this.role == "proUser" || this.role == "sysAdmin"
      },
      {
        text: "证书",
        icon: "mdi-certificate",
        path: "/license",
        hidden: this.role == "sysAdmin"
      }
    ];
    //订单详情页传递的参数
    //this.selectedItem = this.$route.params.selectedType;

    if (typeof this.$route.params.selectedType != "undefined") {
      //从detail页面传过来的tab index值
      if (this.$route.params.selectedType == 5) {
        this.selectedItem = 7;
        this.toPage(this.items[5].path);
        return;
      }
      //if(this.$route.params.selectedType == 3){
      //  this.selectedItem = 3
      //  this.toPage(this.sysItems[0].path)
      //  return
      //}
      this.selectedItem = this.$route.params.selectedType;
      this.toPage(this.items[this.selectedItem].path);
    } else {
      //刷新当前页面 使tab index是当前path对应的index
      let index = this.items.findIndex(item => item.path == this.$route.path);
      if (index == -1) {
        let sysIndex = this.sysItems.findIndex(
          item => "/" + item.path == this.$route.path
        );
        if (sysIndex == -1) {
          index = 0;
        } else {
          index = sysIndex + 4;
        }
      } else if (index >= 5) {
        index = index + 2;
      }
      if (index != this.selectedItem) this.selectedItem = index;
    }
    await this.getOrderList();
    await this.getFinishedProcessResult();
  },
  mounted () { },

  computed: {
    passwordConfirmationRule () {
      return () =>
        this.new_password === this.confirm_password || "Password must match";
    }
  },
  methods: {
    //跳转到指定页面
    toPage (param) {
      if (param.indexOf("/") == -1) param = "/" + param;
      console.log(param, this.$route.path);
      //异常处理避免显示错误
      if (this.$route.path != param) this.$router.push(param);
    },
    async getUserInfo () {
      let res = await axios.get(`${commonUrl}listOneUser${version}`);
      if (this.role == "projectAdmin") {
        this.invitationCode = res.data.result.invitationCode;
        this.internal = res.data.result.internal;
      }
    },
    tips (param) {
      this.snackbar = true;
      this.text = param[0];
      this.color = param[1];
    },
    setInvitationCode (invitationCode) {
      this.invitationCode = invitationCode;
    },
    //回退
    back () {
      this.$router.back(-1);
    },
    //登出
    logout () {
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      this.$router.push("/");
    },
    //获得订单列表
    async getOrderList () {
      let res = await axios.get(`${commonUrl}getOrderList${version}`);
      this.orders = res.data;
    },
    //获得完成的遥感图像的数量
    async getFinishedProcessResult () {
      for (let i = 0; i < this.orders.length; i++) {
        await axios
          .post(
            `${commonUrl}listProcessResult${version}`,
            qs.stringify({
              orderId: this.orders[i].id
            })
          )
          .then(res => {
            if(!(res.data instanceof Array)) return
            for (let result of res.data) {
              this.imageLength += result.img.length;
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  },
  watch: {
    "$route.path": function (val) {
      console.log(val);
      //当前页面path变化时，tab index值也相应发生变化
      let index = this.items.findIndex(item => item.path == val);
      if (index == -1) {
        index = this.sysItems.findIndex(item => "/" + item.path == val) + 4;
      } else if (index >= 5) {
        index = index + 2;
      }
      if (index != this.selectedItem) this.selectedItem = index;
    },
    isInternal () {
      return this.internal;
    }
  }
};
/**
 * 时间格式转换
 */
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};
</script>
<style scoped>
/* 可以在选择器中使用/deep/或>>>来创建应用于子组件内部元素的样式规则 */
.order-item-hover:hover {
  cursor: pointer;
  background-color: rgba(229, 194, 218, 0.1);
}
::v-deep .order-table-hover tbody tr:hover {
  cursor: pointer;
}
::v-deep .order-table-hover tbody tr:nth-of-type(odd) {
  background-color: rgba(229, 194, 218, 0.1);
}
.v-navigation-drawer__border {
  width: 0px;
}
.no_padding {
  padding: 0px;
}
</style>
